/* MenuPopver.jsx */
.menu-item-box {
  transition: background 0.3s ease, justify-content 0.3s ease !important;
}
.menu-item-box:hover {
  padding-left: 80px !important;
}

.dot {
  visibility: hidden !important;
  transition: visibility 0.3s ease, color 0.3s ease;
}
.menu-item-text {
  transition: color 0.3s ease;
}

.menu-item-box:hover .dot {
  color: #2400ff !important;
  visibility: visible !important;
}
.menu-item-box:hover .menu-item-text {
  color: #fff !important;
}

@media screen and (max-width: 568px) {
  .menu-item-box:hover {
    padding-left: 50px !important;
  }
}

/* HeroSection.jsx */
.hero-heading {
  color: #fff !important;
  font-style: normal;
  text-transform: uppercase !important;
}

.hero-input {
  width: 100%;
  display: flex;
  height: 62px;
  padding: 0px 32px 0px 59px;
  align-items: center;
  gap: 14px;
  border-radius: 106px;
  font-size: 16px;
  color: #fff;
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.hero-input:hover {
  border: 1px solid #fff;
}

.star-icon {
  position: absolute;
  top: 19px;
  left: 27px;
}

@media screen and (max-width: 968px) {
  .hero-url-box {
    width: 90% !important;
  }
}

.hero-container {
  scrollbar-width: none !important;
}
