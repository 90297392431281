.image-container {
  width: 100%;
  height: 400vh;
  position: relative;
  overflow: hidden;
  animation: scroll-image ease-in forwards;
  animation-timeline: scroll();
  animation-range-start: 0vh;
  animation-range-end: 200vh;
}

@keyframes scroll-image {
  0% {
    scale: 1;
  }
  10% {
    scale: 1.2;
  }
  20% {
    scale: 1.3;
  }
  50% {
    scale: 1.2;
  }
  100% {
    scale: 1;
  }
}

.fixed-content {
  width: 100%;
  color: #fff;
  position: fixed;
  font-size: 60px;
  z-index: 2;
  animation: heading linear forwards;
  animation-timeline: scroll();
  animation-range-start: 300vh;
  animation-range-end: 350vh;
}

h1 {
  color: #fff;
}

@keyframes heading {
  from {
    top: 25%;
  }
  to {
    top: -25%;
  }
}

.main {
  display: flex;
  flex-direction: column;
}

.view {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
  }
}
