.featureSubheading {
  -webkit-text-stroke-width: 2px !important;
  -webkit-text-stroke-color: #fff !important;
  color: #0c1b30 !important;
  font-family: "Poppins", "Plus Jakarta Sans" !important;
  font-style: normal;
  line-height: 0.8 !important;
}

.featureOddSubheading {
  -webkit-text-stroke-width: 2px !important;
  -webkit-text-stroke-color: #060c16 !important;
  color: #fff !important;
  font-family: "Poppins", "Plus Jakarta Sans" !important;
  font-style: normal;
  line-height: 0.8 !important;
}
