.css-1ex1afd-MuiTableCell-root {
  padding: 0px !important;
}

.tabelCell {
  color: #fff !important;
  height: 15px !important;
  padding: 2px !important;
  border-color: rgba(255, 255, 255, 0.05) !important;
}
.tabelLightCell {
  color: #44546f !important;
  height: 15px !important;
  padding: 5px !important;
  border-color: rgba(255, 255, 255, 0.05) !important;
}

.tabelHeader {
  color: #fff !important;
  padding: 12px 12px 12px 5px !important;
  font-weight: 550 !important;
}
.tableLightHeader {
  color: #44546f !important;
  font-weight: 550 !important;
  padding: 12px 12px 12px 5px !important;
}

.custom-table {
  overflow-x: auto !important;
  scrollbar-width: thin !important;
}

@media screen and (max-width: 668px) {
  .delete-modal-container {
    width: 90% !important;
  }
}
