.why-heading {
  color: #060c16 !important;
  font-style: normal;
  text-transform: uppercase !important;
  cursor: pointer !important;
  transition: all 0.5s ease-in-out !important;
}

.why-heading:hover {
  letter-spacing: 6px !important;
}

.whyAndWhereHeading {
  transition: all 0.3s ease !important;
  cursor: pointer !important;
  line-height: 0.8 !important;
}

.firstParentBox:hover .whyAndWhereHeading {
  background: linear-gradient(90deg, #022143 0.28%, #060c15 116.66%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.firstParentBox:hover .numbersBox {
  display: flex !important;
}

.firstParentBox:hover .firstDot {
  color: #060c16 !important;
}

.firstParentBox:hover .firstNo {
  color: #060c16 !important;
}

.secondParentBox:hover .whyAndWhereHeading {
  background: linear-gradient(90deg, #022143 0.28%, #060c15 116.66%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.secondParentBox:hover .numbersBox {
  display: flex !important;
}

.secondParentBox:hover .firstDot {
  color: #060c16 !important;
}

.secondParentBox:hover .firstNo {
  color: #060c16 !important;
}

/* WhereSection.jsx */
.where-heading {
  color: #fff !important;
  font-style: normal;
  text-transform: uppercase !important;
  cursor: pointer !important;
  transition: all 0.5s ease-in-out !important;
}

.where-heading:hover {
  letter-spacing: 6px !important;
}

.wherefirstParentBox:hover .whyAndWhereHeading {
  color: #fff !important;
}

.wherefirstParentBox:hover .numbersBox {
  display: flex !important;
}

.wherefirstParentBox:hover .firstDot {
  color: #fff !important;
}

.wherefirstParentBox:hover .firstNo {
  color: #fff !important;
}

/* Media quary for responsieve screen */
@media screen and (max-width: 568px) {
  .why-heading {
    letter-spacing: 10px !important;
  }
  .where-heading {
    letter-spacing: 10px !important;
  }
}

/* Try */
.hover-text-container {
  position: relative;
  overflow: hidden;
  margin-top: -16px;
}

.hover-text {
  display: inline-block;
  cursor: pointer;
  height: 120px;
}

.character {
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  color: #dbdbdb;
  font-size: 133px;
  line-height: 120px;
}

.hover-text:hover .character {
  top: 0;
  opacity: 1;
  animation: slide-text 1.2s linear infinite;
  background: linear-gradient(90deg, #022143 0.28%, #060c15 116.66%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.hover-text-container:hover ~ .numbersBox {
  display: flex;
}

.hover-text:hover span:nth-child(1) {
  animation-delay: 0s;
}
.hover-text:hover span:nth-child(2) {
  animation-delay: 0.05s;
}
.hover-text:hover span:nth-child(3) {
  animation-delay: 0.1s;
}
.hover-text:hover span:nth-child(4) {
  animation-delay: 0.15s;
}
.hover-text:hover span:nth-child(5) {
  animation-delay: 0.2s;
}
.hover-text:hover span:nth-child(6) {
  animation-delay: 0.25s;
}
.hover-text:hover span:nth-child(7) {
  animation-delay: 0.3s;
}
.hover-text:hover span:nth-child(8) {
  animation-delay: 0.35s;
}
.hover-text:hover span:nth-child(9) {
  animation-delay: 0.4s;
}

@keyframes slide-text {
  0%,
  100% {
    top: -100%;
    opacity: 0;
  }
  10% {
    top: -50%;
    opacity: 0.5;
  }
  20% {
    top: 0;
    opacity: 1;
  }
  98% {
    top: 0;
    opacity: 1;
  }
  99% {
    top: 0;
    opacity: 0;
  }
}

/* Animate numbersBox after the last character */
.hover-text:hover ~ .numbersBox {
  display: flex;
  animation: slide-numbers 0.5s ease forwards;
  animation-delay: 0.6s;
}

/* Keyframes for sliding in the numbersBox */
@keyframes slide-numbers {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Where */
.hover-text-container1 {
  position: relative;
  overflow: hidden;
  margin-top: -16px;
}

.hover-text1 {
  display: inline-block;
  cursor: pointer;
  height: 120px;
}

.character1 {
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 133px;
  line-height: 120px;
}

.hover-text1:hover .character1 {
  top: 0;
  opacity: 1;
  animation: slide-text 1.2s linear infinite;
  /* animation: slide-text 1.5s linear forwards infinite; */
}

.hover-text-container1:hover ~ .numbersBox {
  display: flex;
}

/* .hover-text1:hover span:nth-child(1) {
  animation-delay: 0s;
}
.hover-text1:hover span:nth-child(2) {
  animation-delay: 0.1s;
}
.hover-text1:hover span:nth-child(3) {
  animation-delay: 0.2s;
}
.hover-text1:hover span:nth-child(4) {
  animation-delay: 0.3s;
}
.hover-text1:hover span:nth-child(5) {
  animation-delay: 0.4s;
}
.hover-text1:hover span:nth-child(6) {
  animation-delay: 0.5s;
}
.hover-text1:hover span:nth-child(7) {
  animation-delay: 0.6s;
}
.hover-text1:hover span:nth-child(8) {
  animation-delay: 0.7s;
}
.hover-text1:hover span:nth-child(9) {
  animation-delay: 0.8s;
} */

/* .hover-text1:hover span:nth-child(1) {
  animation-delay: 0s;
}
.hover-text1:hover span:nth-child(2) {
  animation-delay: 0.13s;
}
.hover-text1:hover span:nth-child(3) {
  animation-delay: 0.26s;
}
.hover-text1:hover span:nth-child(4) {
  animation-delay: 0.39s;
}
.hover-text1:hover span:nth-child(5) {
  animation-delay: 0.52s;
}
.hover-text1:hover span:nth-child(6) {
  animation-delay: 0.65s;
}
.hover-text1:hover span:nth-child(7) {
  animation-delay: 0.78s;
}
.hover-text1:hover span:nth-child(8) {
  animation-delay: 0.91s;
}
.hover-text1:hover span:nth-child(9) {
  animation-delay: 1.04s;
} */
.hover-text1:hover span:nth-child(1) {
  animation-delay: 0s;
}
.hover-text1:hover span:nth-child(2) {
  animation-delay: 0.05s;
}
.hover-text1:hover span:nth-child(3) {
  animation-delay: 0.1s;
}
.hover-text1:hover span:nth-child(4) {
  animation-delay: 0.15s;
}
.hover-text1:hover span:nth-child(5) {
  animation-delay: 0.2s;
}
.hover-text1:hover span:nth-child(6) {
  animation-delay: 0.25s;
}
.hover-text1:hover span:nth-child(7) {
  animation-delay: 0.3s;
}
.hover-text1:hover span:nth-child(8) {
  animation-delay: 0.35s;
}
.hover-text1:hover span:nth-child(9) {
  animation-delay: 0.4s;
}

/* Animate numbersBox after the last character */
.hover-text1:hover ~ .numbersBox {
  display: flex;
  animation: slide-numbers 0.5s ease forwards;
  animation-delay: 0.6s;
}

/* Keyframes for sliding in the numbersBox */
@keyframes slide-numbers {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsieve */
@media screen and (max-width: 868px) {
  .character {
    font-size: 100px;
    line-height: 80px;
  }
  .hover-text-container {
    margin-top: -16px;
  }
  .hover-text {
    height: 80px;
  }
  .character1 {
    font-size: 100px;
    line-height: 80px;
  }
  .hover-text-container1 {
    margin-top: -16px;
  }
  .hover-text1 {
    height: 80px;
  }
}

@media screen and (max-width: 668px) {
  .character {
    font-size: 50px;
    line-height: 60px;
  }
  .hover-text-container {
    margin-top: -16px;
  }
  .hover-text {
    height: 60px;
  }
  .character1 {
    font-size: 50px;
    line-height: 60px;
  }
  .hover-text-container1 {
    margin-top: -16px;
  }
  .hover-text1 {
    height: 60px;
  }
}
