.openBoxes{
    padding: 0 40px;
    transition: .5s linear !important;
    /* transition-property: height !important; */
    overflow: hidden;
}
.openBoxes:hover{
    height: 350px;
    background: orange;
}
.contactInputs{
    all: unset;
    background-color: rgba(250,250,250,0.1);
    padding: 15px 20px;
    color: white;
    font-size: 18px;
}
.contactInputs::placeholder{
    color: white;
}
.iframe{
    height: 620px;
}
@media screen and (max-width:1200px) {
    .iframe{
        height: 500px;
    }
}