.testimonial-container {
  width: 90% !important;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  color: #fff;
  position: relative;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user-box {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  transition: background 0.3s ease;
  border-radius: 30px;
  height: 107px;
  padding: 0px 41px;
  width: 469px;
  margin-right: 50px;
}

.user-box.active {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.06);
  box-shadow: 0px 76px 127.9px 0px rgba(0, 0, 0, 0.75);
  position: relative !important;
  right: 50px;
}

.user-image {
  border-radius: 100%;
  width: 63px;
  height: 63px;
  margin-right: 15px;
  object-fit: cover;
  border: 1px solid #2c2c2e;
}

.user-details h4 {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.user-details p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.review-box {
  max-width: 35%;
  padding: 20px;
}

.rating {
  font-size: 25px;
  color: #ffbf00;
  margin-top: 20px;
}

.review-heading {
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 16px;
}
.review-text {
  color: #525d73;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.review-icon-div {
  position: relative;
  right: 30px;
  margin-bottom: 16px;
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.autoAnimateText {
  -webkit-text-stroke-width: 2px !important;
  -webkit-text-stroke-color: #515e74 !important;
  color: #060c16 !important;
  font-family: "Poppins", "Plus Jakarta Sans" !important;
  font-style: normal;
  line-height: 0.8 !important;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .testimonial-container {
    gap: 25px !important;
  }

  .user-box {
    width: 100%;
    padding: 0px 25px;
  }

  .review-box {
    width: 35%;
  }

  .user-details h4 {
    font-size: 18px;
  }

  .user-details p {
    font-size: 14px;
  }

  .review-box {
    max-width: 70%;
  }

  .review-heading {
    font-size: 22px;
  }
}

@media (max-width: 992px) {
  .testimonial-container {
    flex-direction: column;
    gap: 30px;
  }

  .user-box {
    margin-right: 0;
    width: 100%;
  }

  .review-box {
    width: 85%;
  }

  .user-list {
    width: 500px;
  }

  .review-text {
    font-size: 14px;
  }

  .rating {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .user-box {
    height: 80px;
  }
  .user-list {
    max-width: 60%;
  }

  .user-image {
    width: 40px;
    height: 40px;
  }

  .user-details h4 {
    font-size: 16px;
  }

  .user-details p {
    font-size: 12px;
  }

  .review-box {
    max-width: 90%;
    padding: 15px;
  }

  .review-heading {
    font-size: 16px;
  }

  .review-text {
    font-size: 12px;
  }

  .rating {
    font-size: 16px;
  }
  .arrow-buttton {
    padding: 0px !important;
  }
  .svg-arrow {
    width: 80px;
    height: 50px;
  }
}

@media (max-width: 576px) {
  .testimonial-container {
    gap: 20px;
  }

  .user-box {
    height: 70px;
    padding: 10px 20px;
    border-radius: 20px;
  }

  .user-image {
    width: 30px;
    height: 30px;
  }

  .user-details h4 {
    font-size: 14px;
  }

  .user-details p {
    font-size: 12px;
  }

  .review-heading {
    font-size: 14px;
  }

  .review-text {
    font-size: 12px;
  }

  .rating {
    font-size: 14px;
  }
  .svg-arrow {
    width: 55px;
    height: 55px;
  }
}
