body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0;
  /* overflow-y: scroll; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiDataGrid-panel {
  top: -140px !important;
}

.MuiDataGrid-row {
  border-radius: 10px !important;
  border: 1px solid transparent !important;
}

.custom-light-grid .MuiDataGrid-row:nth-child(odd) {
  background: #f6f6ff;
}

.custom-light-grid .MuiDataGrid-row:hover {
  background: #f1f1ff !important;
  border: 1px solid #c5b2ff !important;
  transition: 0.3s;
}
.custom-dark-grid .MuiDataGrid-row:nth-child(even) {
  background: rgba(255, 255, 255, 0.05) !important;
}

.custom-dark-grid .MuiDataGrid-row:hover {
  /* background: rgba(255, 255, 255, 0.05) !important; */
  background: rgba(255, 255, 255, 0.11) !important;
  border: 1px solid #717295 !important;
  transition: 0.3s;
}

/* temp */
.MuiDataGrid-toolbarContainer {
  display: none !important;
}

/* Headers style */
/* .MuiDataGrid-virtualScrollerRenderZone {
  border: 1px solid #fff !important;
  width: 100% !important;
  padding: 20px !important;
} */

.css-qvtrhg-MuiDataGrid-virtualScroller {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.css-qvtrhg-MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.css-qvtrhg-MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.07) !important;
}

.css-qvtrhg-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #6231f7 !important;
}

.custom-dark-grid .MuiDataGrid-columnHeaders {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06) !important;
}
.custom-light-grid .MuiDataGrid-columnHeaders {
  border-bottom: 1px solid #e8e8fa !important;
}

.custom-light-grid .MuiDataGrid-columnHeaders {
  background-color: #fff !important;
}

.custom-dark-grid .muiGridHeader {
  color: #fff !important;
  font-weight: bold !important;
}
.custom-ligth-grid .muiGridHeader {
  color: #060c16 !important;
  font-weight: bold !important;
}

.custom-dark-grid .muiGridHeader svg {
  color: #fff !important;
}
.custom-ligth-grid .muiGridHeader svg {
  color: #060c16 !important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

/* Light */
.tableBody {
  overflow-y: auto !important;
  width: 100% !important;
}

.tableBody::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.tableBody::-webkit-scrollbar-track {
  background: #f6f6ff !important;
}

.tableBody::-webkit-scrollbar-thumb {
  background: #6231f7 !important;
}

/* dark */
.darkTableBody {
  overflow-y: auto !important;
  width: 100% !important;
}

.darkTableBody::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.darkTableBody::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.07) !important;
}

.darkTableBody::-webkit-scrollbar-thumb {
  background: #6231f7 !important;
}

/* Calender date picker */
.rdrMonth {
  width: auto !important;
}
.rdrCalendarWrapper {
  width: 100% !important;
}
