.gm-style-mtc {
  display: none;
}

.gmnoprint {
  display: none;
}
.gm-style-cc {
  display: none;
}

.gm-control-active {
  top: 10px !important;
  right: 20px !important;
  border-radius: 50% !important;
}



